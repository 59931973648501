/* body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  height: 100vh;
  overflow-x: hidden;
} */

body::-webkit-scrollbar {
  display: none;
}

.register form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  max-width: 430px;
  min-width: 320px;
}

input,
select,
textarea {
  margin: 10px 0;
  padding: 0.8em 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #000;
}

.register label {
  font-weight: medium;
  font-size: 11px;
  display: none;
}
.register button {
  background: #000;
  border: none;
  border-radius: 5px;
  color: #fff;
  width: 100%;
  padding: 0.8em 0;
  margin: 1em 0;
}

.register button:hover {
  background: #333;
}
