@tailwind base;

@tailwind components;

@tailwind utilities;

*::-webkit-scrollbar {
  display: block;
}

body {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

main {
  min-width: 360px;
  padding: 0;
  margin: 0;
}

nav,
.nav {
  min-width: 320px;
  max-width: 420px;
  position: sticky;
}

div[class="fixed"],
div[class="absolute"] {
  min-width: 320px;
  width: 360px;
  max-width: 420px;
}

.custom-checkbox {
  /* display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px;
  align-items: center; */
  position: relative;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  width: 30px;
  height: 30px;
  background: #fff;
  border: 1px solid #ddd;
  /* border-radius: 50%; */
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: -1;
}

.checkbox-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000;
  font-size: 11px;
  pointer-events: none;
  z-index: 0;
}

.custom-checkbox input[type="checkbox"]:checked {
  background: #007bff;
  border-color: #007bff;
}

.custom-checkbox span:first-child {
  grid-column: 1;
  justify-self: center;
  color: #fff;
  font-size: 14px;
}

.backdrop,
.fixed {
  z-index: 100;
  max-width: 420px;
  height: inherit;
  display: flex;
  position: absolute;
}

button:hover,
a:hover {
  transform: scaleZ(1.05);
  box-shadow: 1px 1px 0.15em 0.15em rgba(128, 128, 128, 0.4);
  border-radius: 5px;
}

@media (min-width: 768px) {
  .backdrop,
  .fixed {
    max-width: 100%;
  }

  button:hover,
  a:hover {
    transform: none;
    box-shadow: none;
  }
}
